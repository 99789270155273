import { Query } from '@datorama/akita';
import { UserSettingsState, UserSettingsStore, userSettingsStore } from './userSettings.store';

export class UserSettingsQuery extends Query<UserSettingsState> {
    reportSettings$ = this.select(state => state.reports);
    
    constructor(protected store: UserSettingsStore) {
        super(store);
    }

}

export const userSettingsQuery = new UserSettingsQuery(userSettingsStore);