import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { featuresQuery } from '../../features/state';
import { AuthorizedActions, AuthorizedActionsRoutes } from './authorization.constants';
import {workspacesQuery} from "../../reports/workspaces-list-state";


export class AuthorizationQuery {

    isUserAuthorizedToAnyAction$ = combineLatest([workspacesQuery.isUserAuthorized, featuresQuery.isUserAuthorized])
        .pipe(map(x => {
            return x.some(y => y)
        }));

    isLoadingAuthorizedActions$ = featuresQuery.selectLoading();

    authorizedActions$ = featuresQuery.featuresList$.pipe(map(features => {
        return features.map(feature => {
            const mappedFeature = feature as unknown as AuthorizedActions;
            return { actionId: mappedFeature, route: this.getActionRoute(mappedFeature) };
        })
    }))



    getActionRoute(actionId: AuthorizedActions) {
        return AuthorizedActionsRoutes[actionId]
    }

}

export const authorizationQuery = new AuthorizationQuery();
