import { Query } from '@datorama/akita';
import { WorkspacesState, workspacesStore, WorkspacesStore } from './workspaces-list.store';

export class WorkspacesQuery extends Query<WorkspacesState> {
    workspacesList$ = this.select(state => state.workspaces);
    isUserAuthorized = this.select(state => state.isUserAuthorized);

    constructor(protected store: WorkspacesStore) {
        super(store);
    }

}

export const workspacesQuery = new WorkspacesQuery(workspacesStore);
