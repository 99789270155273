import { EntityState, Store, StoreConfig } from '@datorama/akita';
import { FileUpload } from './file-upload.model';

export interface FileUploadState {
  file: File | null,
  inProgress: boolean;
  completed: boolean;
  uploadProgress: number;
}

const initialState = {
  file: null,
  inProgress: false,
  uploadProgress: 0,
  completed: false,
};

@StoreConfig({
  name: 'fileUpload'
})
export class FileUploadStore extends Store<FileUploadState> {

  constructor() {
    super(initialState);
  }

}

export const fileUploadStore = new FileUploadStore();
