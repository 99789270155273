import React from "react";
import AuthComponent from "./modules/authentication/components/authentication.component";
import { AuthenticationFailedView, ProtectedRoutesView } from './views'
import { ThemeProvider } from '@material-ui/core/styles'
import eqTheme from './themes/eq.theme';
import { configure } from './configuration';
configure();

const AppComponent = () => {
    return (
        <ThemeProvider theme={eqTheme}>
            <AuthComponent authenticationPassedComponent={ProtectedRoutesView()} authenticationFailedComponent={AuthenticationFailedView()} />
        </ThemeProvider>
    );
}

export default AppComponent;

