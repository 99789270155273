import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import Navbar from '../modules/shared/components/navbar';
import { uiQuery } from '../modules/shared/state';
import { userSettingsService } from '../modules/shared/state/userSettings';
import ReportsView from './reports.view';
import FileUploadView from './file-upload.view';
import Snackbar from '../modules/shared/components/snackbar';
import UnauthorizedView from "./unauthorized.view";
import ContactView from "./contact.view";
import { MenuItemsSection } from "../modules/shared/models/menuItemsSection";
import { authorizationQuery } from '../modules/authorization/state';
import ProtectedRoute from '../modules/shared/routing/protected.route';
import { AuthorizedActions } from '../modules/authorization/state';
import WelcomeView from "./welcome.view";
import { AddToHomeScreen } from "../modules/pwa/components";


export function ProtectedRoutesView() {

    const [menuItems, setMenuItems] = useState(new Array<MenuItemsSection>());
    const [isUserAuthorized, setIsUserAuthorized] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        uiQuery.isMenuLoading$.subscribe(x => setIsLoading(x));

        const isUserAuthorizedSubscription = authorizationQuery.isUserAuthorizedToAnyAction$.subscribe(x => setIsUserAuthorized(x));

        const menuItemsSubscription = uiQuery.menuItems$.subscribe(x => setMenuItems(x));

        return () => {
            menuItemsSubscription.unsubscribe();
            isUserAuthorizedSubscription.unsubscribe();
        }
    }, []);

    const setReportAsFavorite = (id: string, isFavorite: boolean) => {
        userSettingsService.setReportAsFavorite(id, isFavorite);
    }

    return (<Router>
        <AddToHomeScreen/>
        <Navbar menuItemsSections={menuItems} isLoading={isLoading} setReportAsFavorite={setReportAsFavorite}>
            <>
                {!isUserAuthorized && <Redirect to='/unauthorized' />}
                <Snackbar />
                <Switch>
                    <Route exact path="/" component={WelcomeView}/>
                    <Route path="/contact" component={ContactView} />
                    <Route path="/reports/:workspaceId/:reportId" component={ReportsView} />
                    <Route path="/dashboards" component={() => <h1>Dashboards</h1>} />
                    <Route path="/unauthorized" component={UnauthorizedView} />
                    <ProtectedRoute actionId={AuthorizedActions.ALLOW_FILE_UPLOAD} component={<FileUploadView />} />
                    <Route component={() => <h1>Page not found</h1>} />
                </Switch>
            </>
        </Navbar>
    </Router>);
}


