import React, { useEffect, useState } from "react";
import Alert from '@material-ui/lab/Alert';
import ContactUs from '../modules/contact/components';
import { authorizationQuery } from '../modules/authorization/state/'
import { Redirect } from "react-router-dom";
import UnauthorizedComponent from '../modules/shared/components/unauthorized.component';

export default function Unauthorized() {
    const [isUserAuthorized, setIsUserAuthorized] = useState(false);

    useEffect(() => {
        const isUserAuthorizedSubscription = authorizationQuery.isUserAuthorizedToAnyAction$.subscribe(x => setIsUserAuthorized(x));

        return () => {
            isUserAuthorizedSubscription.unsubscribe();
        }
    }, []);

    return (<div>
        {isUserAuthorized && <Redirect to='/' />}
        <UnauthorizedComponent text='Thank you for creating account. Initially user is not authorized to any actions.' displayContactUs={true} />
    </div>);
}