import React from "react";
import FileUploadComponent from '../modules/fileUpload/components/file-upload.component';

const FileUploadView = () => {
  return (
    <>
      <h1>File Upload</h1>
      <FileUploadComponent/>
    </>
  );
}

export default FileUploadView;