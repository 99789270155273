import { Store, StoreConfig } from '@datorama/akita';

export type ReportUserSettings = {
    reportId: string,
    isFavorite: boolean
}

export interface UserSettingsState {
    reports: { [key: string]: ReportUserSettings; };
}

function createInitialState(): UserSettingsState {
    return {
        reports: {}
    };
}

@StoreConfig({ name: 'userSettings' })
export class UserSettingsStore extends Store<UserSettingsState> {

    constructor() {
        super(createInitialState());
    }

}

export const userSettingsStore = new UserSettingsStore();
