import { Query } from '@datorama/akita';
import { FileUploadStore, FileUploadState, fileUploadStore } from './file-upload.store';

export class FileUploadQuery extends Query<FileUploadState> {
  constructor(protected store: FileUploadStore) {
    super(store);
  }

}

export const fileUploadQuery = new FileUploadQuery(fileUploadStore);
