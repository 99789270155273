import config  from '../configFile';
import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType } from 'react-aad-msal';
import _ from 'lodash';


// Msal Configurations
const defaultConfig: Configuration = {
    auth: {
        clientId: '',
        redirectUri: window.location.origin,
        validateAuthority: false,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

// Authentication Parameters
const defaultAuthenticationParameters: AuthenticationParameters = {
}

// Options
const defaultOptions: IMsalAuthProviderConfig = {
    loginType: LoginType.Popup,
    tokenRefreshUri: window.location.origin
}

const msal = {
    config: _.merge(defaultConfig, config.msalConfig),
    authenticationParameters: _.merge(defaultAuthenticationParameters, config.authenticationParameters),
    options: _.merge(defaultOptions, config.options)
}

export default msal;