import { configureBaseUrl } from "./base-url.interceptor";
import { configureContentTypeJson } from "./content-type.interceptor";
import { configureBearerToken } from "./authorization.interceptor";
import { configureErrorHandler } from './error-handler.interceptor';

export const configure = () => {
    configureBaseUrl();
    configureContentTypeJson();
    configureBearerToken();
    configureErrorHandler();
}