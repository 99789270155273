import { createMuiTheme } from '@material-ui/core/styles'
const theme = createMuiTheme({
    palette: {
        primary: { main: '#DE0001' },
        secondary: { main: '#343534', contrastText: 'white' }
    },
    typography: {
        "fontFamily": `"Avenir", "Calibri", Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif`,
    },
    overrides: {
        MuiButton: {
            root: {
                margin: '5px 0'
            }
        }
    }
})
export default theme;