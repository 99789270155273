import { authQuery } from '../../../authentication/state';
import axios from "axios";
import { ReportUserSettings, userSettingsStore, UserSettingsStore } from "./userSettings.store";

export class UserSettingsService {

    constructor(private userSettingsStore: UserSettingsStore) {
        authQuery.authenticationPassed$.subscribe(isAuthenticated => {
            if (isAuthenticated) {
                this.fetchReportSettings();
            }
        });
    }

    fetchReportSettings() {
        this.userSettingsStore.setLoading(true);
        axios.get<Array<ReportUserSettings>>(`/userSettings/reports`).then((response) => {
            const reports = response.data.reduce<{ [key: string]: ReportUserSettings }>((reportsMap, report) => {
                reportsMap[report.reportId] = report;
                return reportsMap;
            }, {});
            this.userSettingsStore.update({ reports });
        }).catch(x => {
            console.log(x);
        }).finally(() => {
            this.userSettingsStore.setLoading(false);
        })
    }

    setReportAsFavorite(reportId: string, isFavorite: boolean) {
        axios.put<ReportUserSettings>(`/userSettings/reports/${reportId}/favorite`, { isFavorite }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            var newState = { ...this.userSettingsStore.getValue().reports, [reportId]: response.data };
            this.userSettingsStore.update({ reports: newState });
        }).catch(x => {
            console.log(x);
        }).finally(() => {
            this.userSettingsStore.setLoading(false);
        });
    }
}

export const userSettingsService = new UserSettingsService(userSettingsStore);