import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType } from 'react-aad-msal';

const baseUrl = 'https://eqcis-webapi-non-prod-uksouth001.azurewebsites.net/';

// Msal Configurations
const msalConfig: Configuration = {
    auth: {
        authority: 'https://eqcisnonprod.b2clogin.com/eqcisnonprod.onmicrosoft.com/B2C_1_powerbiembeddedlogin/',
        clientId: '4bec9fef-dc43-4fc8-8e0c-30afe1ca9345',
    },
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
    scopes: [
        'openid',
        'https://eqcisnonprod.onmicrosoft.com/4bec9fef-dc43-4fc8-8e0c-30afe1ca9345/renderreport',
        'https://eqcisnonprod.onmicrosoft.com/4bec9fef-dc43-4fc8-8e0c-30afe1ca9345/user_impersonation'
    ]
}

// Options
const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
};

const pwa = {
    cookieExpiration: 14 * 24 * 60 * 60, //seconds
}

const config = {
    baseUrl,
    msalConfig,
    authenticationParameters,
    options,
    pwa,
}

export default config;