import { Query } from '@datorama/akita';
import { UiStore, UiState, uiStore } from './ui.store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { featuresQuery } from '../../features/state';
import { of } from 'rxjs';
import MenuItem from '../models/menuItem';
import ReportMenuItem from '../models/reportMenuItem';
import { MenuItemsSection, MenuSections } from '../models/menuItemsSection';
import { mapWorkspaceModelToGroupedMenuItems} from '../mappers';
import { authorizationQuery, AuthorizedActions } from '../../authorization/state';
import { userSettingsQuery } from './userSettings';
import { workspacesQuery } from "../../reports/workspaces-list-state";

export class UiQuery extends Query<UiState> {
    private securedResources = combineLatest([workspacesQuery.workspacesList$, userSettingsQuery.reportSettings$])
        .pipe(
            map(([workspaces, reportSettings]) => {
                return {
                    id: MenuSections.Reports,
                    title: 'Reports',
                    logo: 'publish_icon',
                    displaySectionHeader: true,
                    childrenItems: workspaces.map(workspace => mapWorkspaceModelToGroupedMenuItems(workspace, reportSettings))
                }
            })
        );

    public favoriteReports$ = this.securedResources.pipe(
        map(section => section.childrenItems.reduce(
            (reports: Array<ReportMenuItem>, groupedMenuItems) => [
                ...reports,
                ...(groupedMenuItems.childrenItems || [])
                    .filter(report => report.isFavorite)]
            , [])
        ));

    private securedActions = featuresQuery.featuresList$.pipe(
        map<string[], MenuItemsSection>(features => ({
            id: MenuSections.SecureActions,
            title: MenuSections[MenuSections.SecureActions],
            displaySectionHeader: false,
            childrenItems: [...features.map<MenuItem>(y => ({
                id: y,
                title: 'File upload',
                logo: 'publish_icon',
                redirectUrl: authorizationQuery.getActionRoute(y as unknown as AuthorizedActions)
            }))]
        })));

    private dashboardAction = of({
        id: MenuSections.UnsecureActions,
        title: MenuSections[MenuSections.UnsecureActions],
        displaySectionHeader: false,
        childrenItems: new Array<MenuItem>(
            { id: 'DASHBOARD', title: 'Dashboard', logo: 'apps', redirectUrl: '/' })
    }
    );

    private notSecuredActions = of({
        id: MenuSections.UnsecureActions,
        title: MenuSections[MenuSections.UnsecureActions],
        displaySectionHeader: false,
        childrenItems: new Array<MenuItem>(
            { id: 'CONTACT_US', title: 'Contact', logo: 'mail_outline', redirectUrl: '/contact' })
    })

    snackbar$ = this.select(state => (state.snackBar));

    isMenuLoading$ = combineLatest([userSettingsQuery.selectLoading(), workspacesQuery.selectLoading(), featuresQuery.selectLoading()])
        .pipe(map(x => x.some(y => y)));

    menuItems$ = combineLatest([this.dashboardAction, this.securedResources, this.securedActions, this.notSecuredActions]);

    constructor(protected store: UiStore) {
        super(store);
    }

}

export const uiQuery = new UiQuery(uiStore);
