import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { uiQuery } from '../state/ui.query';
import { uiService } from '../state/ui.service';
import { Alert } from '@material-ui/lab';
import { SnackBarState } from '../state/ui.store';

export default function SnackbarComponent() {
    const [state, setState] = useState<SnackBarState>({
        open: false,
        severity: 'success',
        message: null
    });

    useEffect(() => {
        const snackbarSubscription = uiQuery.snackbar$.subscribe(x => setState(x));

        return () => {
            snackbarSubscription.unsubscribe();
        }
    }, []);

    const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        uiService.closeSnackbar();
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                open={state.open}
                autoHideDuration={6000}
            >
                <Alert severity={state.severity} onClose={handleClose}>{state.message}</Alert>
            </Snackbar>
        </div>
    )
}