import axios from 'axios';
import baseUrl from '../baseUrl';

export const configureBaseUrl = () => {
    axios.interceptors.request.use(config => {
        config.baseURL = baseUrl;
        return config;
    }, error => {
        Promise.reject(error)
    })
}