import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { IEmbedConfiguration } from "powerbi-client";
import { reportEmbeddedQuery, reportEmbeddedService } from "../report-embedded-state";
import { LinearProgress } from "@material-ui/core";
import FullScreenComponent from '../../shared/components/fullscreen';

const ReportComponent = (props: { workspaceId: string, reportId: string }) => {

    const [embeddingDetails, setEmbeddingDetails] = useState<IEmbedConfiguration>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [report, setReport] = useState<any>(null);

    useEffect(() => {
        const isReportLoadingSubscription = reportEmbeddedQuery.selectLoading().subscribe(x => setIsLoading(x));
        const reportEmbeddedSubscription = reportEmbeddedQuery.embeddingDetails$.subscribe(async (config) => {
            setEmbeddingDetails(config);
            if (report) {
                await report.setAccessToken(config.accessToken);
                await report.refresh();
            }
        });

        reportEmbeddedService.fetchEmbeddingDetails(props.workspaceId, props.reportId);

        return () => {
            isReportLoadingSubscription.unsubscribe();
            reportEmbeddedSubscription.unsubscribe();
        }
    }, [props.workspaceId, props.reportId, report]);

    const onReportLoadedHandler = (_: any, report: any) => {
        setReport(report);
    };

    const onErrorHandler = (event: any) => {
        const hasTokenExpired = event.detail?.message === "TokenExpired";
        if (hasTokenExpired) {
            reportEmbeddedService.fetchEmbeddingDetails(props.workspaceId, props.reportId);
        }
    }

    const eventHandlers =
        new Map([
            ['loaded', onReportLoadedHandler],
            ['error', onErrorHandler],
        ]);

    return (
        <React.Fragment>
            {isLoading && <LinearProgress />}
            {embeddingDetails.accessToken && <FullScreenComponent>
                <PowerBIEmbed
                    embedConfig={embeddingDetails}
                    cssClassName={"report-style-class"}
                    eventHandlers={eventHandlers}
                />
            </FullScreenComponent>}
        </React.Fragment >
    )
}

export default ReportComponent;