import axios from 'axios'
import { BlockBlobClient } from '@azure/storage-blob';
import { TransferProgressEvent } from '@azure/core-http';
import { fileUploadStore } from './file-upload.store';

export class FileUploadService {
  public allowedFileExtensions = [".txt", ".csv", ".tsv"];

  selectFileToUpload(file: File | null) {
    fileUploadStore.update({
      file,
      inProgress: false,
      completed: false,
      uploadProgress: 0,
    })
  }

  isInProgress(inProgress: boolean) {
    fileUploadStore.update({
      inProgress
    });
  }

  uploadCompleted() {
    fileUploadStore.update({
      completed: true
    })
  }

  startUpload() {
    fileUploadStore.update({
      inProgress: true,
      uploadProgress: 0,
    })

    const currentState = fileUploadStore.getValue();

    this.getSaSUploadToken(currentState.file?.name!, currentState.file?.size!)
      .then(response => {
        const { destinationUrl } = response.data;

        const blobClient = new BlockBlobClient(destinationUrl);
        blobClient.uploadData(currentState.file!, {
          onProgress: (progressEvent: TransferProgressEvent) => {
            const progress = (progressEvent.loadedBytes / currentState.file?.size!) * 100;
            fileUploadStore.update({
              uploadProgress: progress
            });
          },
        })
          .then(() => {
            this.isInProgress(false);
            this.uploadCompleted();
          })
          .catch(error => {
            console.error(error);
          });
      });
  }

  getSaSUploadToken(fileName: string, fileSize: number) {
    return axios.get(`/fileupload/sas?fileName=${fileName}&fileSize=${fileSize}`);
  }
}

export const fileUploadService = new FileUploadService();
