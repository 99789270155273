import axios from 'axios';
import { IEmbedConfigurationBase } from 'embed';
import { IEmbedConfiguration, models } from 'powerbi-client';
import { ReportEmbeddedStore, reportEmbeddedStore } from './report-embedded.store';

export class ReportEmbeddedService {

    constructor(private reportEmbeddedStore: ReportEmbeddedStore) {
    }

    fetchEmbeddingDetails(workspaceId: string, reportId: string) {
        this.reportEmbeddedStore.setLoading(true);
        axios.get<IEmbedConfiguration>(`/reports/${workspaceId}/${reportId}`)
            .then((response) => {
                this.reportEmbeddedStore.update({ embedDetails: { ...response.data, ...embedDefaultConfig } });
            }).catch(x => {
                console.log(x);
            }).finally(() => {
                this.reportEmbeddedStore.setLoading(false);
            })
    }
}

const embedDefaultConfig: IEmbedConfigurationBase = {
    type: 'report',
    tokenType: models.TokenType.Embed
}

export const reportEmbeddedService = new ReportEmbeddedService(reportEmbeddedStore);
