import axios from 'axios';
import { uiService } from '../../modules/shared/state/ui.service';
export const configureErrorHandler = () => {
    axios.interceptors.response.use(response => (response)
        , error => {
            let errorMessage = 'Unexpected error, contact system administrator.';
            if (error.response) {
                errorMessage = error.response.data.message;
            } else if (error.request) {
                errorMessage = 'Communication with web server failed, contact system administrator.'
            } else {
                errorMessage = 'Unexpected error, contact system administrator.';
            }
            uiService.openSnackbar(errorMessage, 'error');
            return Promise.reject(error);
        })
}