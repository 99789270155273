import React, { useEffect, useState } from "react";
import { uiQuery } from "../../../shared/state";
import Grid from '@material-ui/core/Grid';
import ReportMenuItem from "../../../shared/models/reportMenuItem";
import { Card, CardContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import AssessmentRoundedIcon from '@material-ui/icons/AssessmentRounded';
import gray from '@material-ui/core/colors/grey';

export default function FavoriteReportsComponent() {
    const [reports, setReports] = useState<ReportMenuItem[]>([]);
    const history = useHistory();
    useEffect(() => {
        const accountInfoSubscription = uiQuery.favoriteReports$.subscribe(x => setReports(x));
        return () => {
            accountInfoSubscription.unsubscribe();
        }
    }, []);

    if (reports.length < 1) {
        return null;
    };

    return (
        <div>
            <Grid container>
                <Grid container alignItems="flex-start" justify="center" spacing={3}>

                    {reports.map(x => (
                        <Grid key={x.id} item>
                            <Card style={{ cursor: 'pointer' }} onClick={() => history.push(x.redirectUrl)}>
                                <CardContent>
                                    <Grid container direction="column" alignItems="center">
                                        <AssessmentRoundedIcon style={{ fontSize: 70, color: gray[300] }} />
                                        <Typography style={{ marginTop: 12 }} variant="h6">
                                            {x.title}
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>);
}