import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType } from 'react-aad-msal';

const baseUrl = 'https://eqmiasswebapi.azurewebsites.net/';

// Msal Configurations
const msalConfig: Configuration = {
    auth: {
        authority: 'https://HazellCarrDev.b2clogin.com/HazellCarrDev.onmicrosoft.com/B2C_1_powerbiembeddedlogin/',
        clientId: '06a5ba80-683d-4e41-81b5-3c99be08cff8',
    },
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
    scopes: [
        'openid',
        'https://HazellCarrDev.onmicrosoft.com/06a5ba80-683d-4e41-81b5-3c99be08cff8/renderreport',
        'https://HazellCarrDev.onmicrosoft.com/06a5ba80-683d-4e41-81b5-3c99be08cff8/user_impersonation'
    ]
}

// Options
const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
};

const pwa = {
    cookieExpiration: 14 * 24 * 60 * 60, //seconds
}

const config = {
    baseUrl,
    msalConfig,
    authenticationParameters,
    options,
    pwa,
}

export default config;