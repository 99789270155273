import { WorkspaceModel } from './workspaces-list.model';
import { authQuery } from '../../authentication/state';
import axios from 'axios';
import { workspacesStore, WorkspacesStore } from "./workspaces-list.store";

export class WorkspacesService {
    constructor(private workspacesStore: WorkspacesStore) {
        authQuery.authenticationPassed$.subscribe(isAuthenticated => {
            if (isAuthenticated) this.fetchWorkspacesWithReports();
        });
    }

    fetchWorkspacesWithReports() {
        this.workspacesStore.setLoading(true);

        axios.get<Array<WorkspaceModel>>(`/reports`).then((response) => {
            const isUserAuthorized = !response.data || response.data.length > 0;
            this.workspacesStore.update({ workspaces: response.data, isUserAuthorized });
        }).catch(x => {
            console.log(x);
        }).finally(() => {
            this.workspacesStore.setLoading(false);
        })
    }
}

export const workspacesService = new WorkspacesService(workspacesStore);
