import { useCallback, useEffect, useState } from "react";
import config from '../../../../configuration/configFile/index';

type PwaPromptEvent = Event & {
    prompt: () => void,
    userChoice: Promise<void>
}

const useMobileDetector = () => {
    const isIos = /ipad|iphone|ipod/.test(window.navigator.userAgent.toLowerCase());
    const isInStandaloneMode = !!(window.navigator as any)?.standalone;

    return { isIos, isInStandaloneMode };
};

const usePwa = () => {
    const [isPwaAvailable, setIsPwaAvailable] = useState(false);
    const [appInstalled, setAppInstalled] = useState(false);
    const [pwaPromptEvent, setPwaPromptEvent] = useState<null | PwaPromptEvent>(null);
    const { isIos, isInStandaloneMode } = useMobileDetector();
    const { isModalInExpiry, setExpiryCookie } = useExpirationCookie(config?.pwa?.cookieExpiration || 14*24*60*60);

    useEffect(() => {
        if ((navigator as any)?.getInstalledRelatedApps){
            (async () => {
                const apps = await (navigator as any).getInstalledRelatedApps();
                if (apps.length > 0) setAppInstalled(true);
            })();
        }

        window.addEventListener('appinstalled', () => {
            setAppInstalled(true);
        });

        window.addEventListener('beforeinstallprompt', (e:Event) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Save event to call it in custom popup.
            setPwaPromptEvent(e as PwaPromptEvent);
        });
    }, []);

    useEffect(() => {
        if (isIos) {
            return setIsPwaAvailable(!isModalInExpiry && !isInStandaloneMode);
        }
        setIsPwaAvailable(!isModalInExpiry && !appInstalled && !!pwaPromptEvent && !isInStandaloneMode);
    }, [isModalInExpiry, pwaPromptEvent, isInStandaloneMode, appInstalled, isIos]);

    const showPwaPrompt = useCallback(() => {
        if (!pwaPromptEvent) return;

        pwaPromptEvent.prompt();
        pwaPromptEvent.userChoice.then(() => setPwaPromptEvent(null));
    }, [ pwaPromptEvent ])

    return {
        isIos,
        isInStandaloneMode,
        setExpiryCookie,
        isPwaAvailable,
        showPwaPrompt,
    }
};

const useExpirationCookie = (expirationSeconds: number) => {
    const isModalInExpiry = !!document.cookie.match('(^|;)\\s*addToHomeScreenExpiration\\s*=\\s*([^;]+)');
    const setExpiryCookie = () => {
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + expirationSeconds);
        document.cookie = `addToHomeScreenExpiration=true; expires=${expirationDate.toUTCString()}; path=/`;
    }

    return { isModalInExpiry, setExpiryCookie }
};

export {
    useExpirationCookie,
    usePwa,
    useMobileDetector
}