import { Button } from "@material-ui/core";
import React from "react";
import { authProvider } from '../modules/authentication/providers/authentication.provider';

export function AuthenticationFailedView() {
    return (
        <>
            <h3>Authentication failed</h3>
            <Button
                variant='contained'
                onClick={() => authProvider.loginRedirect()}
                component='label'
                color='secondary'>
                Try again
            </Button>
        </>
    );
}