import { QueryEntity } from '@datorama/akita';
import { ReportEmbeddedStore, ReportEmbeddedState, reportEmbeddedStore } from './report-embedded.store';

export class ReportEmbeddedQuery extends QueryEntity<ReportEmbeddedState> {
    embeddingDetails$ = this.select(state => state.embedDetails);

    constructor(protected store: ReportEmbeddedStore) {
        super(store);
    }

}

export const reportEmbeddedQuery = new ReportEmbeddedQuery(reportEmbeddedStore);
