import React from "react";
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import ContactOptions from './contact.options';


export default function ContactUs() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (<div>
        <Button aria-describedby={id} variant="contained" color="secondary" onClick={handleClick}>
            Please contact system administrator
      </Button>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <ContactOptions />
        </Popover>
    </div>);
}