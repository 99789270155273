import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType } from 'react-aad-msal';

const baseUrl = 'https://eqcis-webapi-prod-uksouth001.azurewebsites.net';

// Msal Configurations
const msalConfig: Configuration = {
    auth: {
        authority: 'https://eqinsightsonline.b2clogin.com/eqinsightsonline.onmicrosoft.com/B2C_1_powerbiembeddedlogin/',
        clientId: '75eeba9b-c75c-4375-95f6-ddc3552e8601',
    },
};

// Authentication Parameters
const authenticationParameters: AuthenticationParameters = {
    scopes: [
        'openid',
        'https://eqinsightsonline.onmicrosoft.com/75eeba9b-c75c-4375-95f6-ddc3552e8601/renderreport',
        'https://eqinsightsonline.onmicrosoft.com/75eeba9b-c75c-4375-95f6-ddc3552e8601/user_impersonation'
    ]
}

// Options
const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
};

const pwa = {
    cookieExpiration: 14 * 24 * 60 * 60, //seconds
}

const config = {
    baseUrl,
    msalConfig,
    authenticationParameters,
    options,
    pwa,
}

export default config;