import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ReportEmbedded } from './report-embedded.model';

export interface ReportEmbeddedState extends EntityState<ReportEmbedded> { }

export function createInitialState(): ReportEmbeddedState {
    return {
        embedDetails: {},
    };
}

@StoreConfig({
    name: 'report-embedded'
})
export class ReportEmbeddedStore extends EntityStore<ReportEmbeddedState> {
    constructor() {
        super(createInitialState());
    }
}

export const reportEmbeddedStore = new ReportEmbeddedStore();
