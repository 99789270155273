import React, {FormEvent, ChangeEvent, useEffect, useState, useCallback} from "react";
import LinearProgressWithLabel from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { fileUploadQuery, fileUploadService, FileUploadState } from '../state';
import { uiService } from '../../shared/state/';

const FileUploadComponent = () => {
    const [uploadState, setUploadState] = useState<FileUploadState>();

    useEffect(() => {
        const sub = fileUploadQuery
            .select()
            .subscribe((state) => setUploadState(state));

        return () => {
            sub.unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (uploadState?.completed) uiService.openSnackbar('File upload completed', 'success');
    }, [uploadState?.completed]);

    const onFormSubmitHandler = useCallback((event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        fileUploadService.startUpload();
    }, []);

    const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const [file] = Object.values(event.currentTarget.files!);
        fileUploadService.selectFileToUpload(file);
    }, []);

    return (
        <>
            <form onSubmit={onFormSubmitHandler}>
                <div>Accepted file formats: {fileUploadService.allowedFileExtensions.join(', ')}</div>
                <Button
                    variant="contained"
                    color="secondary"
                    component="label">
                    Choose file
                    <input
                        type="file"
                        accept={fileUploadService.allowedFileExtensions.join(',')}
                        onChange={onChangeHandler}
                        hidden
                    />
                </Button>
                <p> {uploadState?.file
                    ? <label>Selected file: <strong>{uploadState?.file?.name}</strong></label>
                    : "Please select file to upload"}
                </p>
                <Button
                    type="submit"
                    disabled={uploadState?.inProgress || uploadState?.file === null}
                    color="secondary"
                    variant="contained"
                >Upload</Button>
            </form>
            { (uploadState?.inProgress || uploadState?.completed)
            && <Box mt={4}><LinearProgressWithLabel variant="determinate" value={uploadState?.uploadProgress}/></Box>}
        </>
    )
}

export default FileUploadComponent;