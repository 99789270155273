import axios from 'axios';
import { authQuery } from '../../modules/authentication/state';

export const configureBearerToken: Function = () => {
    axios.interceptors.request.use(config => {
        //todo authQuery.accountInfo if null
        config.headers['Authorization'] = `Bearer ${authQuery.accountInfo.jwtIdToken}`;
        return config;
    }, error => {
        Promise.reject(error)
    })
}