import { Query } from '@datorama/akita';
import { AuthStore, AuthState, authStore } from './authentication.store';
import { BehaviorSubject } from 'rxjs';

export class AuthQuery extends Query<AuthState> {

    authenticationPassed$ = new BehaviorSubject(false);
    accountInfo$ = new BehaviorSubject(this.accountInfo);

    get accountInfo() {
        return this.getValue().accountInfo;
    }

    constructor(protected store: AuthStore) {
        super(store);

        this.select(state => state.accountInfo).subscribe(this.accountInfo$);
        this.select(state => state.isAuthenticated).subscribe(this.authenticationPassed$);
    }

}

export const authQuery = new AuthQuery(authStore);
