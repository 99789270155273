import React from "react";
import { useParams } from "react-router-dom";
import ReportComponent from '../modules/reports/components/report.component';

export default () => {
    const queryParams = useParams() as { workspaceId: string, reportId: string };

    return (
        <ReportComponent workspaceId={queryParams.workspaceId} reportId={queryParams.reportId} />
    );
}
