import { LinearProgress } from "@material-ui/core";
import React from "react";
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import AccountInfo from "../models/account.info";
import { authProvider } from "../providers/authentication.provider";
import { authService } from '../state';

export default function AuthenticationComponent(props: { authenticationPassedComponent: JSX.Element, authenticationFailedComponent: JSX.Element }) {
    return (<AzureAD provider={authProvider} forceLogin={true}>
        {
            ({ authenticationState, accountInfo }: { authenticationState: AuthenticationState; accountInfo: AccountInfo }) => {
                switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                        authService.userAuthenticationPassed(accountInfo);
                        return (
                            <>
                                {props.authenticationPassedComponent}
                            </>
                        );
                    case AuthenticationState.Unauthenticated:
                        {
                            authService.userAuthenticationFailed();
                            return (
                                <>
                                    {props.authenticationFailedComponent}
                                </>
                            );
                        }
                    case AuthenticationState.InProgress:
                        return (<LinearProgress/>);
                }
            }
        }
    </AzureAD>);
}