import Button from '@material-ui/core/Button';
import { Close } from "@material-ui/icons";
import logo from '../../../../assets/eq_expert_logo.svg';
import IosShareIcon from '../../../../assets/ios_share_icon.png'
import './addToHomeScreen.css'
import { useState} from "react";
import { usePwa} from "./addToHomeScreen.hooks";

const AddToHomeScreen = () => {
    const { isPwaAvailable, isIos, setExpiryCookie, showPwaPrompt } = usePwa();
    const [showModal, setShowModal] = useState(true);

    return (!isPwaAvailable || !showModal) ? null : (
        <div className='add-to-home-screen'>
            <div className='add-to-home-screen__main'>
                <div className='add-to-home-screen__logo'>
                    <img src={logo} className='add-to-home-screen__logo-img' alt=''/>
                </div>
                <div className='add-to-home-screen__text'>
                    <p className='add-to-home-screen__text-title'>
                        EQ Insights Online
                    </p>
                    <span className='add-to-home-screen__text-description'>{window.location.hostname}</span>
                    {isIos &&
                        <span className='add-to-home-screen__text-description-ios'>
                            To add this web app to home screen: tap <img src={IosShareIcon} alt='ios share icon'/> and then <b>Add to Home Screen</b>
                        </span>
                    }
                </div>
                <Button
                    className='add-to-home-screen__close'
                    color={"secondary"}
                    onClick={() => {
                        setExpiryCookie();
                        setShowModal(false);
                    }}
                >
                    <Close/>
                </Button>
            </div>
            {!isIos &&
                <div className='add-to-home-screen__add'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={showPwaPrompt}
                    >
                        ADD TO HOME SCREEN
                    </Button>
                </div>
            }
        </div>
    )
}

export default AddToHomeScreen;