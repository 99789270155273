import { Color } from '@material-ui/lab';
import { UiStore, uiStore } from './ui.store';

export class UiService {

    constructor(private uiStore: UiStore) {
    }

    openSnackbar(message: string, severity: Color) {
        uiStore.update({
            snackBar: {
                open: true,
                severity,
                message
            }
        });
    }

    closeSnackbar() {
        uiStore.update({
            snackBar: {
                open: false,
                severity: 'success',
                message: null
            }
        });
    }
}

export const uiService = new UiService(uiStore);
