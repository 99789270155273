import AccountInfo from '../models/account.info';
import { AuthStore, authStore } from './authentication.store';


export class AuthService {

  constructor(private authStore: AuthStore) {
  }

  userAuthenticationPassed(accountInfo: AccountInfo) {
    this.authStore.update({ isAuthenticated: true, accountInfo: accountInfo });
  }

  userAuthenticationFailed() {
    this.authStore.update({ isAuthenticated: false });
  }

}

export const authService = new AuthService(authStore);
