import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from "@material-ui/core";

const contactOptions = [
    { icon: 'mail_outline', text: 'eqchartersupport@equiniti.com' },
    { icon: 'phone', text: '0333 207 5968' },
    { icon: 'query_builder', text: 'Mon – Fri 9-5:30 excluding weekends and bank holidays' },
];

export default function ContactOptions() {
    return (<React.Fragment>
        <List>
            {contactOptions.map(x => {
                return (
                    <ListItem key={x.text}>
                        <Icon color='action'>{x.icon}</Icon>
                        <ListItemText style={({ padding: '5px' })} primary={x.text} />
                    </ListItem>
                )
            })}
        </List>
    </React.Fragment>);
}