import development from "./development";
import test from "../configFile/test";
import prod from "../configFile/prod";
import local from "../configFile/local";
import uat from "../configFile/uat";

const envKey: string = process.env.REACT_APP_ENVIRONMENT || 'local';

const allConfigs: { [key: string]: any } = { local, development, test, prod, uat };

const currentConfig = allConfigs[envKey];

export default currentConfig;