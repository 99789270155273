import { Store, StoreConfig } from '@datorama/akita';
import AccountInfo from '../models/account.info';


export interface AuthState {
  isAuthenticated: boolean;
  accountInfo: AccountInfo
}

export function createInitialState(): AuthState {
  return {
    isAuthenticated: false,
    accountInfo: {}
  };
}

@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {

  constructor() {
    super(createInitialState());
  }

}

export const authStore = new AuthStore();
