import { Store, StoreConfig } from '@datorama/akita';
import { WorkspaceModel } from "./workspaces-list.model";

export interface WorkspacesState {
    workspaces: Array<WorkspaceModel>;
    isUserAuthorized: boolean;
}

export function createInitialState(): WorkspacesState {
    return {
        workspaces: [],
        isUserAuthorized: true
    };
}

@StoreConfig({ name: 'workspaces' })
export class WorkspacesStore extends Store<WorkspacesState> {
    constructor() {
        super(createInitialState());
    }
}

export const workspacesStore = new WorkspacesStore();
