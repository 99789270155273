import { Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import './index.css'

const FullScreenComponent = (props: { children: JSX.Element }) => {

    const [isFullScreenActive, setIsFullScreenActive] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const isIos = /iPad|iPhone|iPod/.test(navigator.platform);

    useEffect(() => {
        if (containerRef.current) {
            const domElement = containerRef.current;
            domElement.onfullscreenchange = (e) => {
                setIsFullScreenActive(!!document.fullscreenElement);
                const isAndroid = /android/i.test(navigator.platform);

                if (!!document.fullscreenElement && isAndroid) {
                    history.push(history.location);
                }
            }
        }
    }, []);

    const enterFullScreen = () => {
        const domElement = containerRef.current;

        if (isIos) return setIsFullScreenActive(true);
        if (!domElement) return;

        domElement?.requestFullscreen()
            .then(() => {
                setIsFullScreenActive(true);
            });
    }

    return (
        <div className={`context ${isIos && isFullScreenActive && 'ios-fullscreen'}`} ref={containerRef} >
            <div className='context__bar'>
                { isFullScreenActive && isIos &&
                    <Button 
                        variant='contained'
                        onClick={() => setIsFullScreenActive(false)}
                        component='label'
                        color='secondary'>
                        Exit Full screen
                    </Button>
                    }
                { !isFullScreenActive && 
                    <Button
                        variant='contained'
                        onClick={enterFullScreen}
                        component='label'
                        color='secondary'>
                        Full screen mode
                    </Button>
                }
            </div>
            { props.children}
        </div >
    )
}

export default FullScreenComponent;