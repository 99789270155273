import React, { ComponentType, ReactNode, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { authorizationQuery } from '../../authorization/state';
import { AuthorizedActions, AuthorizedActionsRoutes } from '../../authorization/state';
import UnauthorizedComponent from '../../shared/components/unauthorized.component';

function ProtectedRoute(props: {
    component: ReactNode,
    actionId: AuthorizedActions
}) {
    const [isUserAuthorized, setIsUserAuthorized] = useState(true);
    const [isLoadingActions, setIsLoadingActions] = useState(true);

    const route = AuthorizedActionsRoutes[AuthorizedActions[props.actionId]];

    useEffect(() => {
        const isUserAuthorizedSubscription = authorizationQuery.authorizedActions$.subscribe(actions => {
            setIsUserAuthorized(actions.some(action => {
                return action.actionId.toString() === AuthorizedActions[props.actionId]
            }));
        });

        const isLoadingActionsSubscription = authorizationQuery.isLoadingAuthorizedActions$.subscribe(x=> setIsLoadingActions(x));

        return () => {
            isUserAuthorizedSubscription.unsubscribe();
            isLoadingActionsSubscription.unsubscribe();
        }
    }, []);

    return (
        <Route
            path={route}
            render={() => isUserAuthorized || isLoadingActions ? props.component : <UnauthorizedComponent text='User is not authorized for this action.' displayContactUs={false} />}
        />
    );

}

export default ProtectedRoute;